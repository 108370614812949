import React, { useRef, useEffect, useState } from 'react';
import spriteSheet from './spritesheet.png'; // Path to the character sprite sheet

const CharacterCanvas = () => {
  // Canvas and animation references
  const canvasRef = useRef(null);
  const requestRef = useRef();
  const lastTimeStampRef = useRef(0);

  // Character animation constants
  const frameSize = { width: 128, height: 210 };
  const spritesPerRow = 4;
  const idleAnimationSpeed = 300;
  const jumpAnimationSpeed = 80;
  const gravity = -20.81;

  // Character state
  const [isJumping, setIsJumping] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [velocity, setVelocity] = useState(0);
  const [verticalPosition, setVerticalPosition] = useState(0);
  const [score, setScore] = useState(0);
  const currentFrameRef = useRef(0);

  // Draw character function
  const drawCharacter = (context, characterImage) => {
    const canvas = canvasRef.current;
    const deltaTime = performance.now() - lastTimeStampRef.current;

    if (!isStarted) {
      // Idle animation
      if (deltaTime > idleAnimationSpeed) {
        lastTimeStampRef.current = performance.now();
        const row = 2; // Third row (standing sprites)
        const col = currentFrameRef.current % 2;

        const xPos = 0;
        const yPos = canvas.height - frameSize.height - verticalPosition;

        // Draw character
        drawSprite(context, characterImage, col, row, xPos, yPos);

        // Update current frame for next iteration
        currentFrameRef.current = (currentFrameRef.current + 1) % 2;
      }
    } else {
      // Jump animation
      if (deltaTime > jumpAnimationSpeed) {
        lastTimeStampRef.current = performance.now();
        let row, col;

        if (isJumping) {
          setVelocity(v => v + gravity);
          setVerticalPosition(prev => {
            const newPos = prev + velocity;
            if (newPos <= 0) {
              setIsJumping(false);
              return 0;
            }
            return newPos;
          });

          col = 2 + (velocity > 0 ? 0 : 1);
          row = 2;
        } else {
          row = Math.floor(currentFrameRef.current / spritesPerRow);
          col = currentFrameRef.current % spritesPerRow;
          currentFrameRef.current = (currentFrameRef.current + 1) % 8;
        }

        const xPos = 0
        const yPos = canvas.height - frameSize.height - verticalPosition;

        // Draw character
        drawSprite(context, characterImage, col, row, xPos, yPos);
      }
    }
  };

  // Draw sprite helper function
  const drawSprite = (context, image, col, row, xPos, yPos) => {
    const { width, height } = frameSize;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.drawImage(image, col * width, row * height, width, height, xPos, yPos, width, height);
  };

  // Animation frame drawing
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const characterImage = new Image();
    characterImage.src = spriteSheet;

    const drawFrame = () => {
      drawCharacter(context, characterImage);
      requestRef.current = requestAnimationFrame(drawFrame);
    };

    requestRef.current = requestAnimationFrame(drawFrame);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [isStarted, isJumping, velocity, verticalPosition]);

  // Handle jump start
  const startJump = () => {
    if (!isJumping) {
      setIsJumping(true);
      setVelocity(80);
    }
  };

  // Handle animation start
  const handleStart = () => {
    setIsStarted(true);
  };

  const buttonText = isStarted ? 'Jump' : 'Start';

  return (
    <div onClick={isStarted ? startJump : handleStart} className="container-fluid p-0">
      <canvas
     
        ref={canvasRef}
        width={128}
        height={410}
       
      />
     
    </div>
  );
};

export default CharacterCanvas;
