import React, { useState, useRef } from 'react';
import './App.css';
import pointingFinger from './pointing.svg';
import background from './background.webp';
import loudMp3 from './loud.mp3';
import CharacterCanvas from './CharacterCanvas';

function App() {
  const audioRef = useRef();
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true); // State to control the overlay display
  const [showCanvas, setShowCanvas] = useState(false); // State to control the canvas display

  // Function to handle "Accept Cookies" click
  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    setShowOverlay(false); // Hide the overlay
    if (audioRef.current) {
        audioRef.current.play().catch(error => console.log("Playback failed", error));
    }
  };

  // Function to handle clicking on the pointing finger to show the canvas
  const handleStartCanvas = () => {
    setShowCanvas(true);
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      {showOverlay && (
        <div className="overlay" onClick={handleAcceptCookies}>
          <div className="cookie-consent-popup">
            <h2>Cookie Toestemming</h2>
            <p>Deze website maakt gebruik van cookies om ervoor te zorgen dat je de beste ervaring krijgt op onze website.</p>
            <p>Door op "Accepteer Cookies" te klikken, ga je akkoord met het opslaan van cookies op je apparaat om de site-navigatie te verbeteren, het sitegebruik te analyseren en te helpen bij onze marketinginspanningen.</p>
            <p>Voor meer informatie, bekijk onze Privacy Policy</p>
            <button onClick={handleAcceptCookies}>Accepteer Cookies</button>
          </div>
        </div>
      )}

      {/* Display the canvas if showCanvas is true */}
      {showCanvas && <CharacterCanvas />}

      
        {/* Show pointing finger if overlay or canvas is not shown */}
        {(!showOverlay && !showCanvas) && (

          <header className="App-header" style={!showOverlay && !showCanvas ? {} : { filter: 'blur(10px)' }}>
          <img src={pointingFinger} alt="wijzende vinger" onClick={handleStartCanvas} />
          <p>Strafborrel</p>
        <p>Niet op je telefoon zitten tijdens de jaarvergadering.</p>
      </header>
        )}

       

      {/* Audio element */}
      <audio ref={audioRef} src={loudMp3} preload="auto" />
    </div>
  );
}

export default App;
